export const guestList = [{
    "img": "http://file.thinkervc.cn/b1t8lehykizt2yn834njbjtdq0xeidmrcrifrlo1mp5d4zytg7a09dehmjj62urwg4s3dqh5b4j72frm.png",
    "name": "刘 晔",
    "desc": "东方卫视第一财经频道双语财经女主播",
    "context": "· 作为业内知名财经类女主播，以80后的视角，对话中国商界一线大佬。她轻松活泼的主持风格，犀利直接的提问方式，也曾获得了沈南鹏，蔡洪平，董明珠，张兰等众位嘉宾的认可。",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/f979dq3txj2nzdy4mblpnmkzxi7lsjk5xz94nr2u4x76jx7sqkf120grb69ji1ynth9k5pcs9rld0jt2.png",
    "name": "吴世春",
    "desc": "梅花创投创始人",
    "context": "· 互联网老兵，百度早期工程师，酷讯、食神摇摇、基调等项目创始人，知名天使投资人。代表案例：大掌门、趣店、小牛电动、赤子城、理想汽车...",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/4gpy917n1vjikmb3mzew8wg77s2ya4bwh17uchbp3i3owylwizpo5kddp3jpu14uxey8ba94t2gxx7l8.png",
    "name": "徐 新",
    "desc": "今日资本创始人",
    "context": "· 她曾主导和参与了对网易、娃哈哈、长城汽车、真功夫快餐等公司的投资。她投资的企业家中，有4位已成为福布斯前百名富豪，其中京东刘强东和网易丁磊最为代表性。",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/jskjlxeovxsjua54ss71mym616il1efu9xji4lls58rf6tdh9hplbyrcslt04tqbqdjsd9r8adnaugtt.png",
    "name": "徐小平",
    "desc": "真格基金创始人",
    "context": "·中国著名天使投资人，他创立的真格基金连续两年荣获清科“中国股权投资年度最佳天使投资机构”称号。徐小平被称为圈内最受创业者欢迎的天使。",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/123nis5hlkh9z2ho5pzdpj67ae96dzxnxilhsn07zxk7xutstk496ohs6hg3hqpo1806cn3btj5pad68.png",
    "name": "傅 盛",
    "desc": "猎豹移动董事长兼CEO、猎户星空董事长",
    "context": "·2019年12月，当选第十二届中国青年企业家协会副会长 ；2022年，当选首届景德镇景商联合总会会长；2022年7月，傅盛当选为江西省工商联(总商会)副会长",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/83x14iudt1woe4f1zehfhtj4f7595hf7if5i3t3ww1p4jwrx0fy41mn3l30qzbf4hi935rgd4uieifty.png",
    "name": "江南春",
    "desc": "著名企业家、分众传媒创始人",
    "context": "·荣获 2003 “中国当代杰出广告人”称号 ，“2018十大经济年度人物”，2021年4月，福布斯全球富豪榜发布，江南春以62亿美元财富位列榜单第432位",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/61amlnkx3f8xwdw6jmewch69zh3aqp9tuyu9vhhhrm3cg76bmgjldbsaul3yu7vzemqitgnss9rzni6j.png",
    "name": "朱啸虎",
    "desc": "金沙江创投董事总经理",
    "context": "·入选 “2019年中国最具影响力的30位投资人”榜单。 2020年11月17日，登榜“界面新闻2020中国顶级风险投资TOP50”",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/3yubz1hjlpn83jj2s3exlb3nzq14j5gmcsgr39uxgcnxp77aeyw6sy1eargpgyicm405a5a41qqt8oh0.png",
    "name": "缪金生",
    "desc": "预见独角兽创始人",
    "context": "·中科心客 (北京) 科技有限公司董事长。曾获 “中国MBA杰出人物”、 “2016年江西省年度经济人物金融创新奖”、“第七届江西省十大经济人物”等荣誉。",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/r7qpbrtv31ewjbai8q7rp2p9ui9krkg08vd6bkjuwxfc6wzo25ocg4adgvtedx494h8z01z1opvl88by.png",
    "name": "卫 哲",
    "desc": "卓尔集团首席战略官",
    "context": "·中国零售行业具有领先管理理念的领导者 ，担任中国连锁经营协会副会长，荣获2021中国十大品牌年度人物",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/eqrwjva6tkoey0kw2r2976opsvd6si7wet3ktla0mqetiv4id87mfheu6q93dty4s1oz3xk980bp1sw8.png",
    "name": "阎 焱",
    "desc": "赛富投资基金的首席合伙人",
    "context": "·荣获“2004年度中国最佳创业投资人”，“2005年中国十佳创业投资人”，2020年10月14日，入选“2020中国最具影响力的30位投资人”榜单",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/76djl1c1u3zhdjgrc098c14a64xv2zdxpxhcqepns85d21tge08apnbta5x4o20ltacjf171dd2vrqc6.png",
    "name": "周 逵",
    "desc": "红杉资本中国基金合伙人",
    "context": "·2013-2015年福布斯“全球最佳创业投资人100”；2016福布斯全球最佳创投人；2017福布斯全球最佳创投人。",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/v71z62o8iiov5stfdyws9k5y1zbxohqmdyeabojoeu6wnyh4wpft746k5z8qvy5g8fcwmfensjuchfzz.png",
    "name": "周丽霞",
    "desc": "金慧丰投资董事长",
    "context": "·女投资人俱乐部荣誉会长、中国女创投家联谊会执行会长。2022福布斯中国女性创投人20",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/0cwgi34hbkkd59uv61rynkau88hth6ny5kqddkk10c213ly2hggxn04pygr7n3bbn1a0hdz7wvy0znh8.png",
    "name": "胡海泉",
    "desc": "中国内地男歌手、天使投资人",
    "context": "·北京巨匠文化发展有限公司董事长，2014年，进入《财富》中文版公布的“中国40位40岁以下的商界精英”榜单",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/1473u29c1eaht4hkxlcj9rfuphqc2k86ydrd1lzz8q0nbi3la8yl233vera87nl3n7d55jll9xxvva1q.png",
    "name": "姚劲波",
    "desc": "58同城创始人",
    "context": "·2022年6月，湖南省工商业联合会（总商会）企业家副主席，中华全国工商业联合会第十三届执行委员会委员。第十四届全国人民代表大会代表",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/2rn0bi47azjbdgw9gx839g9klnlsz9hpmzz5m0ckzbevxjjrmq347t3mpn69ss07epamjwagv55xj009.png",
    "name": "丁 磊",
    "desc": "网易公司创始人",
    "context": "·《2020中国品牌人物500强》第40位，以2050财富（亿元人民币）位列《2022家大业大酒·胡润全球富豪榜》第37",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/kc95n5stgaek0z12l56ob8s2rbrxmqt1r9ahgeduqtef2k61phdczfehe96p9h0xztayoi32knh06eqn.png",
    "name": "周鸿祎",
    "desc": "360公司创始人",
    "context": "·第十三届、十四届全国政协委员，九三学社中央委员 ，全国工商联大数据运维（网络安全）委员会轮值主席",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/gh6y7ko78wz8jhm5h4lz4s1c243rcmptgpd0qo6oxjoq6mjuumhipebegky6i6wk5a7apkxmp69m7y4n.png",
    "name": "何小鹏",
    "desc": "UC公司的创始人、小鹏汽车董事长",
    "context": "·2021年1月6日，获2020金鸥奖年度创业人物最具成就奖，2022年，以330亿人民币财富位列《2022年衡昌烧坊·胡润百富榜》第151位",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/75mqhpiv2mve7dqa8h7xbdfsqdvgcel8va4sv0t6tntm35h6q4xmz3wvihx2hl17av27re9lbqpqnbxn.png",
    "name": "申晨",
    "desc": "知名媒体评论人 ,天使投资人",
    "context": "·知名媒体评论人，申晨是中央电视台、中国教育电视台及各大卫视多档王牌节目（如《职来职往》、《创赢未来》、《奋斗》、《厨神争霸》、《成长不烦恼》等）的嘉宾。",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/b48idjpp28noqp5ab4jkq0a5gf4qw7g7l0aq73fumm3tk3rwihr0jlv4mqggr8oeh5owx87yu6n5fe8q.png",
    "name": "商业小纸条REAL",
    "desc": "网络大V,抖音粉丝量1676.2W",
    "context": "",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/qmiiq4p0u02vqqqbleinurpice623aeh3dnac1qeug6o1gm35f4k9pwpvqbwvowesfm7cqsq285l80fe.png",
    "name": "崔 磊",
    "desc": "网络大V,抖音粉丝量1449.8W",
    "context": "",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/62blh1pefq94wmpuayhs1rzp2jdtudy8a54xas0e8g6lgo2u13757irweno3nwmv2kog93kt9ikcz55j.png",
    "name": "楠哥有财气",
    "desc": "网络大V,抖音粉丝量805.0W",
    "context": "",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/cj9qymr7rukq2s8x7dygsdak4ffph82etr208c3qs18vquwe8lktt0elxs60qa6ecp1n5o6pqvqp66om.png",
    "name": "高盖伦",
    "desc": "网络大V,抖音粉丝量976.5W",
    "context": "",
    "type": "栏目嘉宾"
  },
  {
    "img": "http://file.thinkervc.cn/q9wjlos58024cd159vel8xgdx1ode5upa2iy1zcscdkojtlft4lo2mjbzu0lhqkzsskbn6v3tw5zmyuc.png",
    "name": "名郑言顺",
    "desc": "网络大V,抖音粉丝量1479.6W",
    "context": "",
    "type": "栏目嘉宾"
  }
]

export const columnValueList1 = [{
    icon: 'http://file.thinkervc.cn/e1jvta7xyjnaizmtujztvlyd19cz80dwpuvxatuphvalzowkn2imnmw5n9uvpjft40d65we0fccw821t.png',
    title: '百位大咖 价值对话'
  },
  {
    icon: 'http://file.thinkervc.cn/43n22899d3vaznlgz888t80gxm7ope67k794688wphyd2y5tvzuvuxsfmnc9sbbns7f9x1ngmrynyh80.png',
    title: '产业资源 对接'
  },
  {
    icon: 'http://file.thinkervc.cn/vwaci2v0zfpjuwzy7fygvbknrgglmsaolh9c2hdrpbbgqlpn7w9vhy0uae1txjhbsxxpbdtlwpzy183u.png',
    title: '百亿投资 等你来拿'
  },
  {
    icon: 'http://file.thinkervc.cn/omgua1f5ybnlttrooazquidrqaekqy2vneuq1x5qmy8qryixmm8wl4oc3qv0vlz12tybmfq4b5tzl7ac.png',
    title: '招商加盟 渠道拓展'
  }
]

export const columnValueList2 = [{
    icon: 'http://file.thinkervc.cn/rllxxwbj1rh1l7o0lyrrlh8c48va4084090tls90ttkqw1o8jorlva79kqktyyydhk7n768ehwt4rr65.png',
    title: '多层次深度 体系服务'
  },
  {
    icon: 'http://file.thinkervc.cn/9drgsxky4l65k1jgpnakfktvp7roqa3n0xy5bvcywtjvikvq06uuos4inkmtyh6f2ny9k5uhq48zs9ek.png',
    title: '百家媒体 集中曝光'
  }
]

export const platformImg1 = [
  "http://file.thinkervc.cn/b58m54mbri3anm6zijmm7uf5ubrkv8zhzr5y290yn3gaaoyqlgsog1ctm1emgjpsaddm8ucwa65erhi2.png"
]

export const platformImg2 = [
  "http://file.thinkervc.cn/wlmbr7jjserxyibn4m3mdrt71x38wn6dqniu2l2yljd922l2o6icj59aoatvsbk54befbzz97wvm29id.png",
  "http://file.thinkervc.cn/nss3uh4xbm44z043ufqxvocj9oge0p8pacaymmo544b3hfecrhzram11o9npk8g27rjnteu0m1zab5k7.png",
  "http://file.thinkervc.cn/ek5qrjtu7ewggtxv1p0aufls5iall1uyrgr7xng8e8e9pb36yrrxpaqgfydfcalw23lgos0eap0z5bi8.png",
  "http://file.thinkervc.cn/8npw1259ityccv543sbaa60vrgw71h3l4uli0usndlfhm785ndu5ppwlbrd85bqunpw07gq9ykn5av6e.png",
  "http://file.thinkervc.cn/sww5825ws6gda6txsy38tzertpjuw2n8e08te4mb51e9c5vw87jurur6lwh3noh6hqzjsvdufzqmhx0n.png",
  "http://file.thinkervc.cn/hkk4dg9qfdgutgi3uvx2g7oiymsy7n7cw86bed7txudl5zbjf0psfeb8ndt0qw2nfclh2iuxvsgn4shu.png",
  "http://file.thinkervc.cn/typkgtlt9c30jhhjk37efohxjq4wajax2l2zf3z35966flxoywcr9v21y61iswzpr21u40cal5revqk0.png",
  "http://file.thinkervc.cn/tsqgk2gj5t9eewnm6ru79jp8b1kn844ua1j0tdydrtuuzpxctlvmcnv4p93mesh70za1rms1nnvxjnsu.png",
  "http://file.thinkervc.cn/ayhdsk4y5nbo86cdjto87hwr1a5xky7bm5qmfrb5rko0my85m33ubetwlxl0xqjl0ij7wudpkc4gp8vg.png",
  "http://file.thinkervc.cn/u3pbx0rszk7kkbmmhv9lgv9516s0apva2za1bm2ax4p4h3qqeqvbtbzmhlgfpc6rawoj3cyp16f0x6zc.png",
  "http://file.thinkervc.cn/bbxgzh28utp8rrcsryrbxyeivcah206h8ih355fmh4ob8y1op3ll1im40htlt06a7bsde1kik1m66kmy.png",
  "http://file.thinkervc.cn/7cezskxboevfwb8fh768r6j2wzk9fqql5kugrjcjhselj6qmeid95i4ona76msa4ndzjbw84ca8sy9so.png",
  "http://file.thinkervc.cn/6pfcebk4oknro31desrm80n2o114cj80leoigkfztqc8aimchhi741zc3z4ipgh4s1438561b2xa6c25.png",
  "http://file.thinkervc.cn/j4hf7yki5ufp5ml66gh2uk5obrfvoaglw07malebykpda6qfr7cq15t8s2yzg3mk1yqbl479dos3dcjz.png",
  "http://file.thinkervc.cn/2j61udysads5jkquaychdp8tlhgig20nfonwidotviopm8918gp3cf3fh94c4yeuvcx879jbhs5icdub.png",
  "http://file.thinkervc.cn/t4wg7r172bzl1agpwyeqa0yjuhhvtnlv1p07wcal5qdyuhdvkje0lj35h32ov9chzlw7wzfdh4reht85.png",
  "http://file.thinkervc.cn/kxwyb5bla4t5o78oicknkx61hxcm7d20637sds3f910d5iz2677xfxudu4zvfamuvkttuqgx4k30y0m7.png",
  "http://file.thinkervc.cn/9nub67lkb4ahsczqinc50qmtou984z0lev3zpai99z1lkurixosh0s17ty8qhrk2dkrs7l3f9o506kwj.png",
  "http://file.thinkervc.cn/vo88ek8ggzhbpfvg36vsu5guexg8yxjlybnlghw4fdhbocujo4n3viia65xgvc3hr7dztwq0r06cv6jf.png",
  "http://file.thinkervc.cn/p3h2eqxjkpfq6pju124k391mqzbct26l5n6unzyv8h1pdsz0c7bxijlmr70vfjj2w9scyx3bc2dz9o8m.png"
]

export const investmentList = [
  "http://file.thinkervc.cn/xmfye4sioh3k4q6gu8oezgsuiezca8kralulynjrimklo9muav1k32mfhexkiuvk2u2xcerd9wqj392e.png"
]